export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'regionCode',
    label: 'field.region',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'claimType',
    label: 'field.type',
    hideToggle: true,
  },
  {
    key: 'claimAmount',
    label: 'field.amount',
    hideToggle: true,
  },
  {
    key: 'invoiceImage',
    label: 'field.invoice',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'claimRemark',
    label: 'field.remark',
    hideToggle: true,
  },
  {
    key: 'purpose',
    label: 'field.purpose',
  },
  {
    key: 'duration',
    label: 'field.duration',
    hideToggle: true,
  },
  {
    key: 'distance',
    label: 'field.distance',
    hideToggle: true,
  },
  {
    key: 'checkinTime',
    label: 'field.checkinTime',
    type: 'datetime',
    tdClass: 'text-success',
  },
  {
    key: 'checkinImage',
    label: 'field.checkinImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'checkinAddress',
    label: 'field.checkinAddress',
    type: 'map',
    latitudeKey: 'checkinLatitude',
    longitudeKey: 'checkinLongitude',
  },
  {
    key: 'checkoutTime',
    label: 'field.checkoutTime',
    type: 'datetime',
    tdClass: 'text-danger',
  },
  {
    key: 'checkoutImage',
    label: 'field.checkoutImage',
    type: 'image',
    thClass: 'text-center',
    tdClass: 'text-center',
  },
  {
    key: 'checkoutAddress',
    label: 'field.checkoutAddress',
    type: 'map',
    latitudeKey: 'checkoutLatitude',
    longitudeKey: 'checkoutLongitude',
  },
];
